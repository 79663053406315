import moment from 'moment'

export const START_DATE_OF_MONTH = value => moment(value)
  .startOf('month')
  .format('YYYY-MM-DD')
  .valueOf()
export const END_DATE_OF_MONTH = value => moment(value)
  .endOf('month')
  .format('YYYY-MM-DD')
  .valueOf()
export const LABELMONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Dec']
export const LABELDAY = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab']
export const YEAR_MONTH_DAY = value => moment(value).format('YYYY-MM-DD')
export const DAY_MONTH_YEAR = value => moment(value).format('DD MMMM YYYY')
export const TIME = value => moment(value).format('HH:mm')
export const START_YEAR_NOW = value => moment(value).startOf('year').format('YYYY').valueOf()
export const MONTH = value => moment(value).format('MM')
export const YEAR = value => moment(value).format('YYYY')
export const FULL_DATE_TIME = value => moment(value).format('DD MMMM YYYY - HH:mm')
export const DATE = value => moment(value).format('DD')
export const DDMMYYYY = value => moment(value).format('DD MMM YYYY')
export const SLASHED_DDMMYYYY = value => moment(value).format('DD/MM/YYYY')
